

import './game_pages.css';
import Button from 'react-bootstrap/Button';
import poster from '../../assets/images/booknooksPoster.png';
import bn_1 from '../../assets/images/booknooks/bn_1.jpg';
import bn_2 from '../../assets/images/booknooks/bn_2.jpg';
import bn_3 from '../../assets/images/booknooks/bn_3.jpg';
import bn_4 from '../../assets/images/booknooks/bn_4.jpg';


function Booknooks() {
  return (
    <body>

        <div className="main_content">
            <div className="page_content">
                <table>
                    <tr>
                        <td>
                            <img src={poster} className="game_poster"/>
                        </td>
                        
                        <td className="table_main">
                            <h1> Book Nooks </h1>
                            <p> Book Nooks is an atmospheric coop puzzle game in which the player uses the power of words to their advantage
                                to manipulate the space around them. It's set in a café's bookshelf, where the two sentient blobs of ink, Nonee and Addie,
                                are given special powers to reach their destination. This game was made as a university project at the Cologne Game Lab
                                where we were prompted to create a Multiplayer game.
                            </p>
                            <h2> Gameplay </h2>
                            <p> Each player controls either Nonee or Addie, either of which has a special ability centered around the concept of 'words'.
                                Nonee has the ability to turn physical objects back into 'Nouns', such as 'Cube' or 'Door', that can be placed at other locations, while Addie can take
                                aspects of objects and turn them into 'Adjectives', such as 'bright' or 'bouncy', that can be applied to other objects. Using these abilities,
                                the players have to solve a number of puzzles and make use of their own individual strengths.</p>
                            <table className="table_info">
                                        <tr>
                                            <td>Position</td>
                                            <td>Lead Programmer</td>
                                        </tr>
                                        <tr>
                                            <td>Game Engine</td>
                                            <td>Unreal Engine 4.25.4</td>
                                        </tr>
                                        <tr>
                                            <td>Context</td>
                                            <td>3rd Semester Project @ CGL</td>
                                        </tr>
                                        <tr>
                                            <td>Creation Period</td>
                                            <td>November 2021 - February 2022</td>
                                        </tr>
                                    </table>
                        </td> 
                    </tr>
                </table>  
                
            </div>
            <div className="page_content_2">
                <table className="content_table">
                    <tr >
                        <td>
                            <iframe className="video_embed" src="https://www.youtube.com/embed/oHei24dUwAs" title="Book Nooks {GameplayTrailer}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>    
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><img src={bn_1} className="galeryImage"/></td>
                                    <td><img src={bn_2} className="galeryImage"/></td>
                                </tr>
                                <tr>
                                    <td><img src={bn_3} className="galeryImage"/></td>
                                    <td><img src={bn_4} className="galeryImage"/></td>
                                </tr>
                            </table>
                        </td>
                    </tr>                    
                </table>
            </div>
        </div>
    </body>

  );
}

export default Booknooks;