

import './game_pages.css';
import Button from 'react-bootstrap/Button';
import bw_1 from '../../assets/images/beeware/bw_1.png';
import bw_2 from '../../assets/images/beeware/bw_2.png';
import bw_3 from '../../assets/images/beeware/bw_3.png';
import bw_4 from '../../assets/images/beeware/bw_4.png';


function Beeware() {
  return (
    <body>

        <div className="main_content">
            <div className="page_content">
                <table>
                    <tr>
                        <td className="table_main">
                            <h1> Beeware </h1>
                            <p> Beeware is a mixed reality puzzle game in which the player is forced to leave the comfort of their home to explore nature and find QR Codes hidden
                                around a local cologne location. Throughout the process of playing each aspect of the game, the player is led to learn more about bees, more
                                specifically, the ways we can help them strive in today's urban environments. The player is guided through the process of creating Bee-hotels,
                                and at the location of 'Gut Leidenhausen' in southeastern cologne, the location the QR codes are hidden, plenty more information about the bees 
                                could be found. This game was created as part of a university project at the Cologne Game Lab where we were prompted to make an experimental game
                                with a focus on the environment.
                            </p>
                            <h2> Gameplay </h2>
                            <p> The gameplay is split into three 'acts'. First, the player explores the beautiful in-game environment, solving puzzles to collect materials that
                                they will then use to construct Bee-hotels. At the same time, they can explore the environment and find clues that they can then bring into the second act.
                                At that point, they will have to leave their house and head to 'Gut Leidenhausen', the location where the QR codes are hidden. There, they can be found
                                using the paper clues of the first act. Using a website they can pull up on their phone to assist them in the process, they can then scan the codes
                                and make their way back home to continue the game into the third act.
                                Now, the world of the game is filled with new wildlife buzzing around them.</p>
                            <table className="table_info">
                                        <tr>
                                            <td>Position</td>
                                            <td>Gameplay Programmer</td>
                                        </tr>
                                        <tr>
                                            <td>Game Engine</td>
                                            <td>Unity 2021.3.3f1</td>
                                        </tr>
                                        <tr>
                                            <td>Context</td>
                                            <td>4th Semester Project @ CGL</td>
                                        </tr>
                                        <tr>
                                            <td>Creation Period</td>
                                            <td>May 2022 - July 2022</td>
                                        </tr>
                                    </table>
                        </td> 
                    </tr>
                </table>  
                
            </div>
            <div className="page_content_2">
                <table className="content_table">
                    <tr >
                        <td>
                            <iframe className="video_embed" src="https://www.youtube.com/embed/MPWZAY578sY" title="Beeware Gameplay"frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>    
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><img src={bw_1} className="galeryImage"/></td>
                                    <td><img src={bw_2} className="galeryImage"/></td>
                                </tr>
                                <tr>
                                    <td><img src={bw_3} className="galeryImage"/></td>
                                    <td><img src={bw_4} className="galeryImage"/></td>
                                </tr>
                            </table>
                        </td>
                    </tr>                    
                </table>
            </div>
        </div>
    </body>

  );
}

export default Beeware;