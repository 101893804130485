import bookNooks from '../assets/videos/bookNooks.mp4';
import chalkboard from '../assets/videos/beyondTheChalkboard.mp4';
import whorigami from '../assets/videos/whorigami.mp4';
import beeware from '../assets/videos/beeware.mp4';
import walkingTitle from '../assets/videos/walkingTitle.mp4';

import './games.css';
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Button from 'react-bootstrap/Button';

function Games() {
  return (
    <body>
        <div className="main_content">
            <Parallax pages={6} style ={{backgroundImage: "linear-gradient(#C3F2EF, #218F87"}}>
                <ParallaxLayer offset={0} speed={0.2}>
                    <div className="games_intro">
                        <h1 className="games_intro_header"> My Games </h1>
                        <p> Down below you can see all of the games that I have made so far!</p>
                        <p> Up to this point, these games have all been created in the context of university projects at the Cologne Game Lab.
                            We have always worked in small teams with talented Artists, Designers, and Programmers! 
                        </p>
                        
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={1} speed={0.2} >
                    <div className="video_overlay"/>
                    <div  className="games_text" >
                        <h1 className="games_text_header"> Book Nooks </h1>
                        <p>Nonee and Addie are small ink creatures that live inside a café’s bookshelf. One day 
                            they discover a crack behind one of the book nooks and find huge miniature puzzleground. 
                            With their special abilities to conjure objects and manipulate their environments properties 
                            they embark on a journey through the hidden the world behind the book nooks.</p>
                        <Button href="/games/booknooks" className="game_buttons"> More Info </Button>
                    </div>
                    <video src={bookNooks} autoPlay loop muted className="videos"/>

                </ParallaxLayer>
                <ParallaxLayer offset={2} speed={0.2}>
                    <div className="video_overlay"/>
                    <div  className="games_text" >
                        <h1 className="games_text_header"> Beyond the Chalkboard </h1>
                        <p> Beyond the Chalkboard is a narrative-driven game about studying with Depression and
                        Anxiety. It gives players a short look into the life of one such student and the struggles and
                        barriers they face in everyday life. </p>
                        <Button href="/games/chalkboard" className="game_buttons"> More Info </Button>
                    </div>
                    <video src={chalkboard} autoPlay loop muted className="videos"/>
                </ParallaxLayer>
                <ParallaxLayer offset={3} speed={0.2}>
                    <div className="video_overlay" />
                    <div  className="games_text" >
                        <h1 className="games_text_header"> Beeware </h1>
                        <p> Take a step away from your Computer in Beeware! In this short game, you are given clues and are then prompted
                            to head outside to a local farm in Cologne to learn more about Bees and how to protect them.
                            Throughout your journey, the world of the game will become filled with more and more life.
                        </p>
                        <Button href="/games/beeware" className="game_buttons"> More Info </Button>
                    </div>
                    <video src={beeware} autoPlay loop muted className="videos"/>
                </ParallaxLayer>
                <ParallaxLayer offset={4} speed={0.2} >
                    <div className="video_overlay"/>
                    <div  className="games_text" >
                        <h1 className="games_text_header"> Whorigami </h1>
                        <p> In this short narrative game, play as a piece of paper taking on different shapes to to travel the world around it,
                            in an attempt to figure out who or what it really is.
                        </p>
                        <Button href="/games/whorigami" className="game_buttons"> More Info </Button>
                    </div>
                    <video src={whorigami} autoPlay loop muted className="videos"/>
                </ParallaxLayer>
                <ParallaxLayer offset={5} speed={0.2} >
                    <div className="video_overlay"/>
                    <div  className="games_text" >
                        <h1 className="games_text_header"> Walking Title </h1>
                        <p> Walking Title is a physics-based game where the player controls a mushroom on stilts, exploring the overgrown ruins of the world around it
                            in this psychedelic balancing game.
                        </p>
                        <Button href="/games/walking" className="game_buttons"> More Info </Button>
                    </div>
                    <video src={walkingTitle} autoPlay loop muted className="videos"/>
                </ParallaxLayer>
            </Parallax>
        </div>
    </body>

  );
}

export default Games;