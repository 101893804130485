import './bottombar.css'

const BottomBar = () => {
    return (
        <div className="bar">
            <p className="barText">
            <b>Contact</b> |||
             <b>  Email</b> dicekaysercontact@gmail.com | 
            <b>  Instagram</b> @diceveer | 
            <b>  Twitter</b> @DiceOnDwidder | 
            </p>
        </div>
    )
}

export default BottomBar