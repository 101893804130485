

import './game_pages.css';
import Button from 'react-bootstrap/Button';
import wt_1 from '../../assets/images/walking/wt_1.png';
import wt_2 from '../../assets/images/walking/wt_2.png';
import wt_3 from '../../assets/images/walking/wt_3.png';
import wt_4 from '../../assets/images/walking/wt_4.png';


function Walking() {
  return (
    <body>

        <div className="main_content">
            <div className="page_content">
                <table>
                    <tr>
                        <td className="table_main">
                            <h1> Walking Title </h1>
                            <p> Walking Title is a physics based walking simulator where the player has to use their balancing skills to move the player through the
                                psychedelic wasteland of the world around them. They will be met with overgrown traces of a civilization long gone through their journey.
                                This game was made as a university project at the Cologne Game Lab, where we were prompted to make a Ludic Game with the theme "Step by Step".
                            </p>
                            <h2> Gameplay </h2>
                            <p> The player takes control of a humanoid, sentient mushroom on stilts. The only way they can move is by carefully controlling the stilts
                                by clicking the left or right mouse button and dragging the mouse away from the direction they want to move, as though they were moving
                                the top of a pair of stilts themselves. They will have to coordinate through a number of obstacles to reach their destination.
                            </p>
                            <table className="table_info">
                                        <tr>
                                            <td>Position</td>
                                            <td>Lead Programmer </td>
                                        </tr>
                                        <tr>
                                            <td>Game Engine</td>
                                            <td>Unity 2020.2.0f1</td>
                                        </tr>
                                        <tr>
                                            <td>Context</td>
                                            <td>1st Semester Project @ CGL</td>
                                        </tr>
                                        <tr>
                                            <td>Creation Period</td>
                                            <td>December 2020 - February 2021</td>
                                        </tr>
                                    </table>
                        </td> 
                    </tr>
                </table>  
                
            </div>
            <div className="page_content_2">
                <table className="content_table">
                    <tr >
                        <td>
                            <iframe className="video_embed" src="https://www.youtube.com/embed/fmjsl2ugjUY" title="Walking Title Gameplay" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>    
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><img src={wt_1} className="galeryImage"/></td>
                                    <td><img src={wt_2} className="galeryImage"/></td>
                                </tr>
                                <tr>
                                    <td><img src={wt_3} className="galeryImage"/></td>
                                    <td><img src={wt_4} className="galeryImage"/></td>
                                </tr>
                            </table>
                        </td>
                    </tr>                    
                </table>
            </div>
        </div>
    </body>

  );
}

export default Walking;